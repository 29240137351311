














































import { Component, Vue } from "vue-property-decorator";
import RecyleDetailCard from "@/views/recyle/components/RecyleDetailCard.vue";
import BlindBox from "@/api/blind-box.api";
import { Toast } from "vant";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
    RecyleDetailCard,
  },
})
export default class RecyleDetail extends Vue {
  nowList: any[number] = [];
  totalPrice = 0;
  openProtocol() {
    if (InteractionLib.isApp()) {
      InteractionLib.openWebView("https://official.vgn.cn/purchase-notes.html");
      return;
    }
    location.href = "https://official.vgn.cn/purchase-notes.html";
  }
  async created() {
    this.nowList = await BlindBox.getMyRecyleSupply(1, 1,this.$route.query?.nowList as string);
    if (this.nowList.length <= 0) {
      return this.toRecyle()
    }
    this.nowList.forEach((item: any) => {
      this.totalPrice += item.recycle_price * 1000;
    });
  }
  toRecyle() {
    this.$router.push({ name: "recyle" });
  }
  async confirm() {
    let supplys: any = [];
    this.nowList.forEach((res: any) => {
      supplys.push({
        id: res.id,
        supply_type: res.supply_type,
      });
    });
    await BlindBox.toRecyleSupply(supplys);
    this.toRecyle();
    Toast("回收成功,可前往钱包查看蒸汽币余额");
  }
}
